import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import DBResourceDetailLightBox from "components/resource/DBResourceDetailLightBox";
import JournalResourceDetailLightBox from "components/resource/JournalResourceDetailLightBox";
import SearchBlock from "components/search/SearchBlock";

import { autobind } from "react-decoration";
import { page } from "components/page";
import qs from "query-string";
import { navigate } from "gatsby";

@withI18next(["common"])
@page
class ResourceDetail extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    this.setState({
      ...state,
    });
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { id, isprint, resourceType } = params;
    let state = {
      id: id,
      isprint: isprint,
      auth: readerStore.auth,
      resourceType: resourceType,
    };
    return state;
  }
  
  render() {
    let { readerStore } = this.props;
    let { auth } = readerStore;
    let { resourceType } = this.state;
    let journalType = false;
    if (
      resourceType === "journal" ||
      resourceType === "paper_journal" ||
      resourceType === "ebook"
    ) {
      journalType = true;
    }
    return (
      <Layout {...this.props}>
        <div className="main lp" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <SearchBlock searchType="resource" />
          {resourceType === "databases" && (
            <section>
              <div className="container">
                <DBResourceDetailLightBox
                  {...this.props}
                  {...this.state}
                  isPage={true}
                />
              </div>
            </section>
          )}
          {journalType && (
            <section>
              <div className="container">
                <JournalResourceDetailLightBox
                  {...this.props}
                  {...this.state}
                  isPage={true}
                />
              </div>
            </section>
          )}
        </div>
      </Layout>
    );
  }
}

export default ResourceDetail;
